<template>
  <div class="pageContainer" style="">
    <div
    class="row   position-relative m-0 p-0 w-100 big-div-quiz"
   
    
    :style="getPageDesign()"
  >
    <!-- BackGroundDiv start-->
  
    <div
       class="bg lazyload"
          :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
      v-if="pageDesign.pageStyles.imageAlign == 'no'"
      :key="getImageSrc(pageDesign.imageBlock , isDesktopView)"
:style="
getbackgroundImage(
IsImageAdded,
pageDesign.imageBlock,
pageDesign.pageStyles.imageAlign , isDesktopView
) + getBgDivStyles()
"
    ></div>
    <!-- BackGroundDiv end-->
    <div
      class=" px-0 px-0"
      v-show="showLeftImage"
      :class="[{
        'col-12 col-lg-12 col-xs-12': !isDesktopView,
        'image-division col-6': isDesktopView,
      }]"
       :style="calculateImageWidth( pageDesign.imageBlock , isDesktopView , IsImageVariableWidthActive)"
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
   :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                    IsImageVariableWidthActive,
                    pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageRotation(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${order}`,
              getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
            alt
/>
        </div>
      </div>
    </div>

    <div
      :class="[!IsImageAdded ? 'col-12' : imageAddedLayout]"
      class=" "
      :style="
        `zoom: ${calcZoom} ;`
        + calculateContentWidth( pageDesign.imageBlock , isDesktopView ,IsImageVariableWidthActive,pageDesign.pageStyles)
      "
    >
       <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100%;
        "
      >
        <div style="overflow: hidden scroll" class="h-100">
          <div   v-if="CurrentPageValue != 'stripePage'">
            <PreviewProgressBar :order="order" />
          </div>
          <div
            v-for="(pro, ind) in pageDesign.blocksArray"
            :key="'preview' + ind"
            class=""
          >
            <div
              id="preview"
              :style="getHeightWidth(pro.style)"
              class="content"
              style="position: relative"
            >
              <div
                v-if="pro.type == 'text'"
                :style="
                  getColorCss(pro.style) +
                  getPaddingCss(pro.style) +
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getLineHeight(pro.style) +
                  getTextAlign(pro.style) +
                  getFontStyle(pro.style,isDesktopView) 
                "
                
                v-html="pro.content"
              ></div>
              <div
                v-if="pro.type == 'html'"
                :style="
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getTextAlign(pro.style)
                "
              >
                <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
              </div>
              <div
                    v-if="pro.type == 'sliderRangeInput'"
                   :style=" getMarginCss(pro.style, isDesktopView)"
                  >
                  <SliderInputComp  :pro="pro" :isDesktopView="isDesktopView" :isEditor="true"/>
                   
                  </div>

                
             
              <div
                v-if="pro.type == 'option'"
              >
                <div>
                  <!-- Checkbox Options -->
                  <div
                    :style="
                      getMarginCss(pro.style, isDesktopView) +
                      getTextAlign(pro.style)
                    "
                  >
                   
                    <div v-if="pro.optionSetting.optionType === 'text'">
                      <div
                        v-if="pro.style.textAlign != 'end'"
                        :class="
                          pro.textOptionAlignment == 'vertical'
                            ? 'd-flex flex-column'
                            : 'd-flex  flex-wrap'
                        "
                      >
                        <div
                          class="form-check checkboxDiv"
                          v-for="(checkbox, index) in pro.options"
                          :key="index"
                          :class="
                            pro.textOptionAlignment == 'horizontal'
                              ? 'mr-2'
                              : 'mb-1'
                          "
                          :style="selectedCheckboxColor(pro.style)"
                        >
                          <div
                            class="d-flex align-items-center w-100"
                            :style="`justify-content:${pro.style.textAlign};`"
                          >
                            <input
                              class="form-check-input m-0 mr-2"
                              type="checkbox"
                              :id="index"
                              :style="{
                                height: pro.style.fontSize + 'px',
                                width: pro.style.fontSize + 'px',
                                position: 'relative',
                              }"
                              :value="checkbox"
                            />
                            <label
                              class="form-check-label m-0"
                              :style="
                                getColorCss(pro.style) +
                                getColorWithOpacity(pro.style) +
                                getFontStyle(pro.style)
                              "
                              :for="index"
                            >
                              {{ checkbox.value }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        :class="
                          pro.textOptionAlignment == 'vertical'
                            ? 'd-flex flex-column'
                            : 'd-flex  flex-wrap'
                        "
                      >
                        <div
                          class="form-check checkboxDiv"
                          v-for="(checkbox, index) in pro.options"
                          :key="index"
                          :class="
                            pro.textOptionAlignment == 'horizontal'
                              ? 'mr-2'
                              : 'mb-1'
                          "
                          :style="selectedCheckboxColor(pro.style)"
                        >
                          <div
                            class="d-flex align-items-center w-100"
                            :style="`justify-content:${pro.style.textAlign};`"
                          >
                            <label
                              class="form-check-label m-0 mr-2"
                              :style="
                                getColorCss(pro.style) +
                                getColorWithOpacity(pro.style) +
                                getFontStyle(pro.style)
                              "
                              :for="index"
                            >
                              {{ checkbox.value }}
                            </label>
                            <input
                              class="form-check-input m-0"
                              type="checkbox"
                              :style="{
                                height: pro.style.fontSize + 'px',
                                width: pro.style.fontSize + 'px',
                                position: 'relative',
                              }"
                              :id="index"
                              :value="checkbox"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="pro.optionSetting.optionType === 'image'">
                      <div class="row">
                        <div
                          v-for="(checkbox, index) in pro.options"
                          :key="index"
                          class="d-flex flex-column"
                          :style="
                            getDivAlignmentStyle(pro.style.textAlign) +
                            getSpaceBetweenRow(pro.style)
                          "
                          :class="
                            isDesktopView
                              ? `col-${12 / pro.OptionsPerRow}`
                              : `col-${12 / pro.OptionsPerMobile}`
                          "
                        >
                          <!-- If Image is not selected -->
                          <div
                            v-if="!checkbox.image"
                            :style="
                              getImageHeightWidth(pro.style, isDesktopView)
                            "
                            class="ImageInputBlock position-relative"
                          >
                            <img
                              :src="MainImgSrc('/images/image_placeholder.png') "
                              alt=""
                              style="cursor: pointer; height: 100%; width: 100%"
                              :style="getBorderRadius(pro.style)"
                            >
                            
                          </div>
                          <!-- If Image is selected -->
                          
                          <div
                            v-else
                            :style="
                              getImageHeightWidth(pro.style, isDesktopView)
                            "
                            class="ImageInputBlock position-relative w-100 h-100 bg-white"
                          >
                            <img
                              :src="ImageSrc(checkbox.image)"
                              alt=""
                              style="cursor: pointer; height: 100%; width: 100%;"
                              :style="getBorderRadius(pro.style)"
                            >
                            <!-- <LazyImage
                              :src="ImageSrc(checkbox.image)"
                              alt=""
                              style="cursor: pointer; height: 100%; width: 100% :z-index:1;"
                              :style="getBorderRadius(pro.style)"
                            /> -->
                           
                          </div>

                          <div>
                            <p
                              class="m-0 p-0 mt-2"
                              :style="
                                getColorCss(pro.style) +
                                getColorWithOpacity(pro.style) +
                                getFontStyle(pro.style)
                              "
                            >
                              {{ checkbox.value }}
                            </p>
                          </div>
                       
                        </div>
                      </div>
                    </div>
                    <div v-if="pro.optionSetting.optionType === 'tab'">
                      <div v-if="pro.textOptionAlignment == 'vertical'">
                        <ul
                          style="
                            max-width: 500px;
                            list-style: none;
                            display: inline-block;
                          "
                          class="m-0 p-0"
                        >
                          <li
                            v-for="(checkbox, index) in pro.options"
                            :key="index"
                            class="mb-2"
                            :class="isDesktopView ? 'Tab' : ''"
                            style="min-width: 200px; max-width: 500px"
                          >
                            <div>
                              <button
                                class="btn tab-btn border w-100"
                                :style="
                                 getDefaultTabStyles(
                                        pro.style.defaultTab,
                                        pro.style
                                      )
                                "
                              >
                                {{ checkbox.value }}
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div v-else class="">
                        <div
                          class="m-0 p-0 row"
                          style="display: flex; flex-wrap: wrap"
                          :style="`height:100%;max-width: 100%; justify-content:${pro.style.textAlign};`"
                        >
                          <div
                            v-for="(checkbox, index) in pro.options"
                            :key="index"
                            style="flex-grow: 1"
                            class="mb-2"
                            :class="[
                              isDesktopView ? 'Tab' : '',
                              isDesktopView
                                ? pro.optionSetting.horizontalOptionsPerRow
                                  ? `col-${
                                      12 /
                                      pro.optionSetting.horizontalOptionsPerRow
                                    }`
                                  : 'col-6 col-sm-6 col-md-4'
                                : pro.optionSetting
                                    .horizontalOptionsPerRowMobile
                                ? `col-${
                                    12 /
                                    pro.optionSetting
                                      .horizontalOptionsPerRowMobile
                                  }`
                                : 'col-6 col-sm-6 col-md-4',
                            ]"
                          >
                            <div style="height: 100% !important">
                              <button
                                class="btn tab-btn border w-100"
                                style="height: 100% !important"
                                :style="
                                   getDefaultTabStyles(
                                        pro.style.defaultTab,
                                        pro.style
                                      )
                                "
                              >
                                {{ checkbox.value }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="pro.optionSetting.optionType === 'dropdown'">
                      <!--  Dropdown Start -->
                      <div class="select-div">
                        <multiselect
                          :options="pro.options"
                          :multiple="true"
                          class="multiSelect"
                          :style="
                            getColorCss(pro.style) +
                            getColorWithOpacity(pro.style) +
                            getFontStyle(pro.style) +
                            QuestionPageStyle(pro.style)
                          "
                          :close-on-select="false"
                          :searchable="false"
                          label="value"
                          track-by="value"
                        >
                          <template slot="maxElements"><span></span></template>
                        </multiselect>
                      </div>
                      <!--  Dropdown End -->
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="pro.type == 'tooltip'"
              
                :style="`display:flex;justify-content: ${
                  pro.style.position
                } ; `"
              >
                <button
                  :style="
                    getButtonCss(pro.style, isDesktopView) +
                    getCircularBtn(pro.isBtnRounded)
                  "
                 
                >
                  <span v-if="pro.useIcon">
                    <BIcon :icon="pro.selectedIcon" />
                  </span>

                  <span
                    v-else
                    :style="{
                      opacity: `${pro.style.textOpacity}%`,
                      fontSize: `${pro.style.fontSize}px`,
                    }"
                  >
                    {{ pro.text }}
                  </span>
                </button>
                <ToolTipComp
                  v-if="pro.isTooltipVisible"
                  :tooltipData="pro"
                  :isDesktopView="isDesktopView"
                />
              </div>


              <!-- Stripe Page Comp start -->
              <div
                  v-if="pro.type == 'paymentButton'"
                  :style="
                    `display:flex;justify-content: ${pro.style.position} ; `
                  "
                >
                  <button :style="getButtonCss(pro.style,isDesktopView)" >
                    <span
                      :style="{
                        opacity: `${pro.style.textOpacity}%`,
                        fontSize: `${pro.style.fontSize}px`,
                      }"
                    >
                      {{ pro.text }}
                    </span>
                    {{ pro.style.alignItems }}
                  </button>
                </div>
                <div v-if="pro.type == 'price'">
                  <div>
                    <p class="m-0" :style="getPriceCss(pro.style)">
                      <span>{{ pro.selectedCurrency.currencySymbol }}</span
                      >{{ pro.amountValue }}
                    </p>
                  </div>
                </div>
              <!-- Stripe Page Comp End -->


              <!-- LEad PAges Start -->
              <div v-if="pro.type == 'freeText'" class="FreetextBlock">
                        <div
                          :style="
                            getMarginCss(pro.style,isDesktopView) + getTextAlign(pro.style)
                          "
                        >
                          <div class="mb-1" v-if="pro.showLabel">
                            <p class="m-0 p-0 text-left" :style="leadLabelStyle(pro.style)">
                              {{ pro.labelText }}
                              <span v-if="pro.isRequired" style="color: red"
                                >*</span
                              >
                            </p>
                          </div>
                          <div
                            :style="
                              pro.style.borderType === 'border'
                                ? getInputBorderCSS(pro.style)
                                : getInputBorderBottomCSS(pro.style)
                            "
                          >
                            <input
                              v-if="pro.selectedInfoType.fieldName !== 'date' && pro.selectedInfoType.fieldName !== 'phone_number'"
                              :type="pro.selectedInfoType.type.toLowerCase()"
                              :placeholder="pro.InfoTypePlaceholder"
                              :required="pro.isRequired"
                              :style="customInputStyles(pro.style)"
                              class="text-dark font-weight-medium date-input CustomTextInput"
                            />
                          
                            <div v-else-if="pro.selectedInfoType.fieldName !== 'date' && pro.selectedInfoType.fieldName == 'phone_number'">
                              
                              <VueTelInput
                   
                    :inputOptions="{
        placeholder:pro.InfoTypePlaceholder,
        maxlength: 15,
      }"
                    :dropdownOptions="dropdown"
                    mode="international"
                    :validCharactersOnly="true"
                    :style="customInputStyles(pro.style)"
                              class="text-dark font-weight-medium date-input CustomTextInput telephoneInput "
                  />
                            </div>
                            <div v-else>
                              <div class="row m-0 p-0" v-if="pro.isDatePicker">
                                <!-- <b-form-datepicker :label-no-date-selected="pro.selectedInfoType.datePickerPlaceholder" reset-button class="CustomDatePicker"  :style="customInputStyles(pro.style)"></b-form-datepicker> -->
                                <div  class="w-100">
                                  <input
                                    class="form-control defaultCalendar"
                                    type="date"
                                    :style="customInputStyles(pro.style)"
                                    :placeholder="
                                      pro.selectedInfoType.datePickerPlaceholder
                                    "
                                    ref="CustomDateInputRef"
                                  />
                                </div>
                              </div>
                              <div class="row m-0 p-0" v-else>
                                <div
                                  class="d-flex"
                                  v-if="pro.selectedDateFormat == 'DDMMYYYY'"
                                >
                                  <div class="d-flex flex-column px-1">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.dateText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="DD"
                                        :required="pro.isRequired"
                                        :min="1"
                                        :style="customInputStyles(pro.style)"
                                        :max="31"
                                        v-model="dateInputValue"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                  <!-- seperator -->
                                  <div
                                    class="d-flex justify-content-center mr-2"
                                  >
                                    <span
                                      class="mb-0"
                                      style="align-self: end"
                                      :style="getDateTextColor(pro.style)"
                                      >{{
                                        pro.selectedDateFormatSeperator
                                      }}</span
                                    >
                                  </div>
                                  <!-- seperator -->
                                  <div class="d-flex flex-column">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.monthText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="MM"
                                        v-model="monthInputValue"
                                        :required="pro.isRequired"
                                        :min="1"
                                        :style="customInputStyles(pro.style)"
                                        :max="12"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                  <!-- seperator -->
                                  <div
                                    class="d-flex justify-content-center mr-2"
                                  >
                                    <span
                                      class="mb-0"
                                      style="align-self: end"
                                      :style="getDateTextColor(pro.style)"
                                      >{{
                                        pro.selectedDateFormatSeperator
                                      }}</span
                                    >
                                  </div>
                                  <!-- seperator -->
                                  <div class="d-flex flex-column">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.yearText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="YYYY"
                                        v-model="yearInputValue"
                                        :min="1900"
                                        :max="9999"
                                        :style="customInputStyles(pro.style)"
                                        :required="pro.isRequired"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="d-flex"
                                  v-if="pro.selectedDateFormat == 'MMDDYYYY'"
                                >
                                  <div class="d-flex flex-column">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.monthText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="MM"
                                        v-model="monthInputValue"
                                        :required="pro.isRequired"
                                        :min="1"
                                        :style="customInputStyles(pro.style)"
                                        :max="12"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                  <!-- seperator -->
                                  <div
                                    class="d-flex justify-content-center mr-2"
                                  >
                                    <span
                                      class="mb-0"
                                      style="align-self: end"
                                      :style="getDateTextColor(pro.style)"
                                      >{{
                                        pro.selectedDateFormatSeperator
                                      }}</span
                                    >
                                  </div>
                                  <!-- seperator -->
                                  <div class="d-flex flex-column">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.dateText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="DD"
                                        :required="pro.isRequired"
                                        :min="1"
                                        v-model="dateInputValue"
                                        :style="customInputStyles(pro.style)"
                                        :max="31"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>

                                  <!-- seperator -->
                                  <div
                                    class="d-flex justify-content-center mr-2"
                                  >
                                    <span
                                      class="mb-0"
                                      style="align-self: end"
                                      :style="getDateTextColor(pro.style)"
                                      >{{
                                        pro.selectedDateFormatSeperator
                                      }}</span
                                    >
                                  </div>
                                  <!-- seperator -->
                                  <div class="d-flex flex-column">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.yearText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="YYYY"
                                        v-model="yearInputValue"
                                        :min="1900"
                                        :style="customInputStyles(pro.style)"
                                        :max="9999"
                                        :required="pro.isRequired"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="d-flex"
                                  v-if="pro.selectedDateFormat == 'YYYYMMDD'"
                                >
                                  <div class="d-flex flex-column">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.yearText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="YYYY"
                                        v-model="yearInputValue"
                                        :min="1900"
                                        :max="9999"
                                        :style="customInputStyles(pro.style)"
                                        :required="pro.isRequired"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                  <!-- seperator -->
                                  <div
                                    class="d-flex justify-content-center mr-2"
                                  >
                                    <span
                                      class="mb-0"
                                      style="align-self: end"
                                      :style="getDateTextColor(pro.style)"
                                      >{{
                                        pro.selectedDateFormatSeperator
                                      }}</span
                                    >
                                  </div>
                                  <!-- seperator -->
                                  <div class="d-flex flex-column">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.monthText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="MM"
                                        v-model="monthInputValue"
                                        :required="pro.isRequired"
                                        :min="1"
                                        :max="12"
                                        :style="customInputStyles(pro.style)"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                  <!-- seperator -->
                                  <div
                                    class="d-flex justify-content-center mr-2"
                                  >
                                    <span
                                      class="mb-0"
                                      style="align-self: end"
                                      :style="getDateTextColor(pro.style)"
                                      >{{
                                        pro.selectedDateFormatSeperator
                                      }}</span
                                    >
                                  </div>
                                  <!-- seperator -->
                                  <div class="d-flex flex-column">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.dateText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="DD"
                                        v-model="dateInputValue"
                                        :required="pro.isRequired"
                                        :min="1"
                                        :max="31"
                                        :style="customInputStyles(pro.style)"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="d-flex"
                                  v-if="pro.selectedDateFormat == 'DDMM'"
                                >
                                 <div class="d-flex flex-column px-1">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.dateText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="DD"
                                        :required="pro.isRequired"
                                        :min="1"
                                        :style="customInputStyles(pro.style)"
                                        :max="31"
                                        v-model="dateInputValue"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                  <!-- seperator -->
                                  <div
                                    class="d-flex justify-content-center mr-2"
                                  >
                                    <span
                                      class="mb-0"
                                      style="align-self: end"
                                      :style="getDateTextColor(pro.style)"
                                      >{{
                                        pro.selectedDateFormatSeperator
                                      }}</span
                                    >
                                  </div>
                                  <!-- seperator -->
                                  <div class="d-flex flex-column">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.monthText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="MM"
                                        v-model="monthInputValue"
                                        :required="pro.isRequired"
                                        :min="1"
                                        :style="customInputStyles(pro.style)"
                                        :max="12"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="d-flex"
                                  v-if="pro.selectedDateFormat == 'MMDD'"
                                >
                               <div class="d-flex flex-column">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.monthText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="MM"
                                        v-model="monthInputValue"
                                        :required="pro.isRequired"
                                        :min="1"
                                        :style="customInputStyles(pro.style)"
                                        :max="12"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                  <!-- seperator -->
                                  <div
                                    class="d-flex justify-content-center mr-2"
                                  >
                                    <span
                                      class="mb-0"
                                      style="align-self: end"
                                      :style="getDateTextColor(pro.style)"
                                      >{{
                                        pro.selectedDateFormatSeperator
                                      }}</span
                                    >
                                  </div>
                                  <!-- seperator -->
                                  <div class="d-flex flex-column">
                                    <div
                                      class="d-inline"
                                      style="text-align: left"
                                    >
                                      <span
                                        :style="
                                          getDateTextColor(pro.style) +
                                          getDateTextFontStyle(pro.style)
                                        "
                                        >{{
                                          pro.selectedInfoType.dateText
                                        }}</span
                                      >
                                    </div>

                                    <div>
                                      <input
                                        :type="
                                          pro.selectedInfoType.type.toLowerCase()
                                        "
                                        placeholder="DD"
                                        :required="pro.isRequired"
                                        :min="1"
                                        v-model="dateInputValue"
                                        :style="customInputStyles(pro.style)"
                                        :max="31"
                                        class="text-dark font-weight-medium date-input"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- checkbox -->
                      <div v-if="pro.type == 'checkbox'">
                        <div
                          :style="
                            getMarginCss(pro.style,isDesktopView) + getTextAlign(pro.style)
                          "
                        >
                          <div
                            class="d-flex align-items-center  "
                            :style="getcheckboxRotate(pro.style)"
                          >
                            <div
                              class="form-check d-flex align-items-center m-0 p-0 w-100 checkboxDiv "
                              :style="`justify-content:${pro.style.textAlign};flex-direction:${pro.style.textAlign == 'end' ? `row-reverse` : `row`};`"
                            >
                            
                              <input
                                type="checkbox"
                                class="form-check-input m-0 "
                        :class="pro.style.textAlign == 'end' ? `ml-2` : `mr-2`"
                                :id="`customCheckbox-${Date.now()}`"
                                 :style="{ height: pro.style.fontSize + 'px', width: pro.style.fontSize + 'px',position:'relative' }"
                              />
                              <label
                               class="form-check-label m-0"
                                :for="`customCheckbox-${Date.now()}`"
                                :style="
                                  getColorCss(pro.style) +
                                  getColorWithOpacity(pro.style) +
                                  getFontStyle(pro.style)
                                "
                                ><span
                                  >{{ pro.text }}
                                  <a
                                    class=""
                                    style="
                                      text-decoration: underline;
                                      cursor: pointer;
                                    "
                                    :style="getLinkStyle(pro.style)"
                                   
                                    target="_blank"
                                    >{{ pro.linkText }}</a
                                  ></span
                                > 
                                <span v-if="pro.isRequired" style="color: red"
                                >*</span
                              >
                                
                                </label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- checkbox -->
                      <div v-if="pro.type == 'freeTextArea'">
                        <div
                          :style="
                            getMarginCss(pro.style,isDesktopView) + getTextAlign(pro.style)
                          "
                        >
                          <div class="mb-1" v-if="pro.showLabel">
                            <p class="m-0 p-0 text-left" :style="leadLabelStyle(pro.style)">
                              {{ pro.labelText }}
                              <span v-if="pro.isRequired" style="color: red"
                                >*</span
                              >
                            </p>
                          </div>
                          <div
                            class=" "
                            :style="
                              pro.style.borderType === 'border'
                                ? getInputBorderCSS(pro.style)
                                : getInputBorderBottomCSS(pro.style)
                            "
                          >
                            <textarea
                              type="text"
                              :placeholder="pro.InfoTypePlaceholder"
                              :required="pro.isRequired"
                              
                              :style="freeTextInputStyles(pro.style)"
                              id="textInput"
                              rows="1"
                              style="resize: none; overflow: auto"
                              class="text-input p-1"
                            />
                          </div>

                          <!-- Limit Characters Div start-->

                          <div
                            v-if="Object.prototype.hasOwnProperty.call(pro, `limitFreeTextArea`) &&  pro.limitFreeTextArea.limitFreeTextAreaValue"
                            class="text-right pr-2"
                          >
                            <span
                            v-if="pro.limitFreeTextArea.limitSelectionType == 'max'"
                              :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                              >{{ leadTextValue.length }}/{{
                                pro.limitFreeTextArea.maxFreeTextAreaValue
                              }}</span
                            >
                            <span
                            v-if="pro.limitFreeTextArea.limitSelectionType == 'min'"
                              :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                              >{{
                                pro.limitFreeTextArea.minFreeTextAreaValue
                              }}/{{ leadTextValue.length }}</span
                            >
                            <span
                            v-if="pro.limitFreeTextArea.limitSelectionType == 'exact'"
                              :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                              >{{
                                pro.limitFreeTextArea.exactFreeTextAreaValue
                              }}</span
                            >
                          </div>
                          <!-- Limit Characters Div end-->
                        </div>
                      </div>

                      <!-- Auth -->
                      <div v-if="pro.type == 'Auth'">
                    <div
                      :style="
                        getMarginCss(pro.style, isDesktopView) 
                       
                      "
                    >
                      <div class="mb-1" v-if="pro.showLabel">
                        <p
                          class=""
                          :style="verifyOtpTextStyles(pro.style)"
                        >
                          {{ pro.VerifyOTPText }}
                          
                        </p>
                      </div>

                        
                      <div
                       
                      >
                      <AuthOtpInputComp :pro="pro" class="mb-3"/>
                        <div class="d-flex " :style="`justify-content:${pro.style.sendBtnPosition};`">
                          <div class="d-flex flex-column">
                          <button
                          class="btn "
                          :style="authButtonStyles(pro.style)"
                        >
                          {{ pro.verifyAuthText }}
                        </button>
                       
                        <button
                      
                      class="btn pb-0 "
                      style="font-size: 14px"
                      :style="`color:${pro.style.strokeColor}`"
                    >
                      <BIconArrowLeft class="mr-2" /> {{ pro.goBackBtnText }}
                    </button>
                    <button
                          class="btn p-0"
                          style="font-size: 14px"
                          :style="`color:${pro.style.strokeColor}`"
                        >
                          {{ pro.resendOtpText }}
                        </button>
                        </div>
                        </div>  
                        
                      </div>
                      
                    </div>
                  </div>
                      <!-- Auth end-->

                    <!-- Address Start -->

                    <div v-if="pro.type == 'address'">

                     <div
                          :style="
                            getMarginCss(pro.style,isDesktopView) + getTextAlign(pro.style)
                          "
                        >
                        <div v-if="pro.showAddressLine1">
                           <div class="mb-1" v-if="pro.showAddressLine1Label">
                            <p class="m-0 p-0 " :style="leadLabelStyle(pro.style)">
                              <span v-if="pro.isAddressLine1Required && pro.style.textInputAlign == 'end'" style="color: red"
                                >*</span
                              >
                              {{ pro.AddressLine1Label }}
                              <span v-if="pro.isAddressLine1Required && (pro.style.textInputAlign == 'center' || pro.style.textInputAlign == 'left')" style="color: red"
                                >*</span
                              >
                            </p>
                          </div>
                          <div
                            :style="
                              pro.style.borderType === 'border'
                                ? getInputBorderCSS(pro.style)
                                : getInputBorderBottomCSS(pro.style)
                            "
                          >
                            <input
                             
                              type="text"
                              :placeholder="pro.AddressLine1Placeholder"
                              :required="pro.isAddressLine1Required"
                              :style="customInputStyles(pro.style)"
                              class="text-dark font-weight-medium date-input CustomTextInput"
                            />
                           
                          </div>
                        </div>
                        <div v-if="pro.showAddressLine2" class="my-2">
                           <div class="mb-1" v-if="pro.showAddressLine2Label">
                            <p class="m-0 p-0 " :style="leadLabelStyle(pro.style)">
                               <span v-if="pro.isAddressLine2Required && pro.style.textInputAlign == 'end'" style="color: red"
                                >*</span
                              >
                              {{ pro.AddressLine2Label }}
                              <span v-if="pro.isAddressLine2Required && (pro.style.textInputAlign == 'center' || pro.style.textInputAlign == 'left')" style="color: red"
                                >*</span
                              >
                            </p>
                          </div>
                          <div
                            :style="
                              pro.style.borderType === 'border'
                                ? getInputBorderCSS(pro.style)
                                : getInputBorderBottomCSS(pro.style)
                            "
                          >
                            <input
                             
                              type="text"
                              :placeholder="pro.AddressLine2Placeholder"
                              :required="pro.isAddressLine2Required"
                              :style="customInputStyles(pro.style)"
                              class="text-dark font-weight-medium date-input CustomTextInput"
                            />
                           
                          </div>
                        </div>
                        <div v-if="pro.showCity" class="my-2">
                           <div class="mb-1" v-if="pro.showCityLabel">
                            <p class="m-0 p-0 " :style="leadLabelStyle(pro.style)">
                              <span v-if="pro.isCityRequired && pro.style.textInputAlign == 'end'" style="color: red"
                                >*</span
                              >
                              {{ pro.CityLabel }}
                              <span v-if="pro.isCityRequired && (pro.style.textInputAlign == 'center' || pro.style.textInputAlign == 'left')" style="color: red"
                                >*</span
                              >
                            </p>
                          </div>
                          <div
                            :style="
                              pro.style.borderType === 'border'
                                ? getInputBorderCSS(pro.style)
                                : getInputBorderBottomCSS(pro.style)
                            "
                          >
                            <input
                             
                              type="text"
                              :placeholder="pro.CityPlaceholder"
                              :required="pro.isCityRequired"
                              :style="customInputStyles(pro.style)"
                              class="text-dark font-weight-medium date-input CustomTextInput"
                            />
                           
                          </div>
                        </div>
                        <div v-if="pro.showState" class="my-2">
                           <div class="mb-1" v-if="pro.showStateLabel">
                            <p class="m-0 p-0 " :style="leadLabelStyle(pro.style)">
                              <span v-if="pro.isStateRequired && pro.style.textInputAlign == 'end'" style="color: red"
                                >*</span
                              >
                              {{ pro.StateLabel }}
                              <span v-if="pro.isStateRequired && (pro.style.textInputAlign == 'center' || pro.style.textInputAlign == 'left')" style="color: red"
                                >*</span
                              >
                            </p>
                          </div>
                          <div
                            :style="
                              pro.style.borderType === 'border'
                                ? getInputBorderCSS(pro.style)
                                : getInputBorderBottomCSS(pro.style)
                            "
                          >
                            <input
                             
                              type="text"
                              :placeholder="pro.StatePlaceholder"
                              :required="pro.isStateRequired"
                              :style="customInputStyles(pro.style)"
                              class="text-dark font-weight-medium date-input CustomTextInput"
                            />
                           
                          </div>
                        </div>
                        <div v-if="pro.showZipCode" class="my-2">
                           <div class="mb-1" v-if="pro.showZipCodeLabel">
                            <p class="m-0 p-0 " :style="leadLabelStyle(pro.style)">
                              <span v-if="pro.isZipCodeRequired && pro.style.textInputAlign == 'end'" style="color: red"
                                >*</span
                              >
                              {{ pro.ZipCodeLabel }}
                              <span v-if="pro.isZipCodeRequired && (pro.style.textInputAlign == 'center' || pro.style.textInputAlign == 'left')" style="color: red"
                                >*</span
                              >
                            </p>
                          </div>
                          <div
                            :style="
                              pro.style.borderType === 'border'
                                ? getInputBorderCSS(pro.style)
                                : getInputBorderBottomCSS(pro.style)
                            "
                          >
                            <input
                             
                              type="text"
                              :placeholder="pro.ZipCodePlaceholder"
                              :required="pro.isZipCodeRequired"
                              :style="customInputStyles(pro.style)"
                              class="text-dark font-weight-medium date-input CustomTextInput"
                            />
                           
                          </div>
                        </div>
                        <div v-if="pro.showCountry" class="my-2">
                           <div class="mb-1" v-if="pro.showCountryLabel">
                            <p class="m-0 p-0 " :style="leadLabelStyle(pro.style)">
                              <span v-if="pro.isCountryRequired && pro.style.textInputAlign == 'end'" style="color: red"
                                >*</span
                              >
                              {{ pro.CountryLabel }}
                              <span v-if="pro.isCountryRequired && (pro.style.textInputAlign == 'center' || pro.style.textInputAlign == 'left')" style="color: red"
                                >*</span
                              >
                            </p>
                          </div>
                          <div
                            :style="
                              pro.style.borderType === 'border'
                                ? getInputBorderCSS(pro.style)
                                : getInputBorderBottomCSS(pro.style)
                            "
                          >
                            <input
                             
                              type="text"
                              :placeholder="pro.CountryPlaceholder"
                              :required="pro.isCountryRequired"
                              :style="customInputStyles(pro.style)"
                              class="text-dark font-weight-medium date-input CustomTextInput"
                            />
                           
                          </div>
                        </div>
                         
                        </div>
                    </div>
                    <!-- Address End -->

                    <div v-if="pro.type == 'fileUpload'" >

                     <div
                          :style="
                            getMarginCss(pro.style,isDesktopView) + getTextAlign(pro.style)
                          "
                        >
                       
                       <FileUploadComp :pro='pro' :isDesktopView="isDesktopView" />
                         
                        </div>
                    </div>
              <!-- LEad PAges End -->
            </div>
          </div>
        </div>

       
        <div>
             <div
             v-if="CurrentPageValue == 'stripePage'"
                  
                    :style="getHeightWidth(StaticButtonElement.style)  "
                    class="content"
                   
                  >
                    <div  class="w-100 "   >
                      <div
                        class="d-flex"
                         :style="
                             ` justify-content: ${StaticButtonElement.style.buttonPosition}`
                          "
                      >
                        <button
                        style="z-index:1000;"
                          :style="
                            getCommonButtonCSS(StaticButtonElement.style,isDesktopView) +
                            getBackNextButtonCss(StaticButtonElement.content.backButton.style,isDesktopView)
                          "
                          
                          
                        >
                          <span
                            :style="{
                              opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                              fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                            }"
                            >{{ StaticButtonElement.content.backButton.text }}</span
                          >
                        </button>
                       
                      </div>
                    </div>
                  </div>
          <div
            v-else
            :style="
              getHeightWidth(StaticButtonElement.style)
            "
            class="content"
          >
            <div class="w-100">
              <div
                class="d-flex"
                :style="
                  getBackNextButtonPosition(
                    StaticButtonElement,
                    CurrentPageValue
                  )
                "
              >
                <button
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.backButton.style,isDesktopView
                    )
                  "
                
                  v-if="!StaticButtonElement.content.backButton.hideBackButton"
                >
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.backButton.text }}</span
                  >
                </button>
                <button
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.nextButton.style,isDesktopView
                    )
                  "
                  v-if="!StaticButtonElement.content.nextButton.hideNextButton"
                >
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.nextButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.nextButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.nextButton.text }}</span
                  >
                </button>
              </div>
            </div>
          </div>

            
        </div>
      </div>
    </div>

    <div
      class=" px-0 px-0"
      v-show="showRightImage"
     :class="[{
'col-12 col-lg-12 col-xs-12': !isDesktopView,
'image-division col-6': isDesktopView,
}]"
 :style="calculateImageWidth( pageDesign.imageBlock , isDesktopView , IsImageVariableWidthActive)"
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
             <LazyImage
                  :style="
                  getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                    IsImageVariableWidthActive,
                    pageDesign.imageBlock
              ) +
                  getImageOpacity(pageDesign.imageBlock.style) +
                  getImageRotation(pageDesign.imageBlock.style)
                "
                :class="[
                  `pic-${order}`,
                  getImageSrc(pageDesign.imageBlock , isDesktopView) == '/images/default_start_page.png'
                    ? 'default-bg'
                    : '',
                ]"
                :src="MainImgSrc(getImageSrc(pageDesign.imageBlock , isDesktopView))"
                alt
                  />
        </div>
      </div>
    </div>

    
  </div>
  </div>
</template>

<script>
import cssFunctionMixin from '../customize/mixin/cssFunction'
import Multiselect from "vue-multiselect";

import { VueTelInput } from "vue-tel-input";

import "vue-tel-input/dist/vue-tel-input.css";
export default {
  components:{
    Multiselect,
    VueTelInput,
    HtmlElement: () =>
      import(
        /* webpackChunkName: "HtmlElement" */ "../quizPreviewComp/HtmlElement.vue"
      ),
   
    ToolTipComp: () =>
      import(
        /* webpackChunkName: "ToolTipComp" */ "../quizPreviewComp/ToolTipComp.vue"
      ),
    PreviewProgressBar: () =>
      import(
        /* webpackChunkName: "PreviewProgressBar" */ "../customize/component/customize/pages/ProgressBar.vue"
      ),
      
    FileUploadComp: () =>
      import(
        /* webpackChunkName: "FilesUploadComp" */ "../quizPreviewComp/FileUploadComp.vue"
      ),
      AuthOtpInputComp: () =>
      import(
        /* webpackChunkName: "AuthOtpInputComp" */ "../quizPreviewComp/AuthOtpInputComp.vue"
      ),
      SliderInputComp: () =>
      import(
        /* webpackChunkName: "SliderInputComp" */ "../quizPreviewComp/components/SliderInputComp.vue"
      ),
  },
props:['pageDesign','order','CurrentPageValue','height','width',"mode"],
mixins: [cssFunctionMixin],
data(){
  return{
    isDesktopView:true,
    leadTextValue: "",
    dropdown: {
        showSearchBox: true,
        showFlags: true,
        width: "390px",
      },
      dateInputValue: null,
      monthInputValue: null,
      yearInputValue: null,
  }
},
computed:{
    // checkDairyFairyCondition(){
    //   if(this.CurrentPage.name==this.theDairyFairyClientObject.name)
    //   {
    //      if( this.isDiaryFairyOptionValue)
    //      return true
    //      return false
    //   }
     
    //   return true
    // },
    calcZoom(){
    if(this.mode == 'firstPage'){
       return this.IsImageAdded && ( this.showLeftImage || this.showRightImage) ? '40%' : `${this.responsiveZoomWithoutImage}%`
    } else {
      return this.IsImageAdded && ( this.showLeftImage || this.showRightImage) ? '10%' : '15%'
    }
  },
     responsiveZoomWithoutImage() {
      // Get the current window width
      const windowWidth = window.innerWidth;
     
      // Calculate the proportional zoom percentage based on the ratio of window width to 1920
      const proportionalZoom = Math.floor((windowWidth / 1920) * 45);

      // Ensure the calculated zoom percentage is not lower than the baseZoom
      const zoomPercentage = Math.max(45, proportionalZoom);

      return zoomPercentage;
    },
    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },
    staticButton() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index].staticButton;
    },
showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.pageDesign.pageStyles.imageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.pageDesign.pageStyles.imageAlign == "left" ||
            this.pageDesign.pageStyles.imageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.pageDesign.pageStyles.imageAlign == "right"
      );
    },
     IsImageAdded() {
      if (this.getImageSrc(this.pageDesign.imageBlock , this.isDesktopView) !== "") return true;
      return false;
    },
    IsImageVariableWidthActive(){
return this.IsImageAdded && this.pageDesign.pageStyles.imageAlign != "no" ? true : false
},
    imageAddedLayout() {
      if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
     return "col-12 col-xs-12 col-sm-6 col-md-6  ";
    },
},
methods:{
  getMultiMax() {
      return 3;
    },
    getMultiMin() {
      return 1;
    },
  getBgDivStyles() {
      return `position:absolute;top: 0;
        bottom: 0;
        left: 0;
        right: 0;width: 100%;
          height: 100%; z-index: 0;`;
    },
    getcheckboxRotate(property) {
      return `  transform: rotate(-${
        property.strokeRotation || 0
      }deg);--selectedCheckboxColor : ${property?.color || "#000000"};`;
    },
     getInputBorderCSS(property) {
      return (
        "border:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    getInputBorderBottomCSS(property) {
      return (
        "border-bottom:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
  getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; width:" + this.width + "px;height:"+ this.height +"px;overflow:hidden;" + (this.mode == "firstPage"
          ? " border-top-left-radius: 12px; border-top-right-radius: 12px;"
          : "") ;

      return design;
    },
     ImageSrc(img) {
      // return img.startsWith('/http') ? img.substring(1) : img
      let newImage;
      if (img.startsWith("/http")) {
        newImage = img.substring(1);
      } else {
        newImage = img;
      }
      if (
        newImage.indexOf("http://") === 0 ||
        newImage.indexOf("https://") === 0
      ) {
        return newImage;
      } else {
        const baseURL = process.env.VUE_APP_API_BASE_URL;
        return baseURL + newImage.substring(1);
      }
    },
},
mounted(){
  this.$emit('loaded');
}
}
</script>

<style scoped>
.pageContainer{
  max-height :100%;
  max-width :100%;
  z-index:-1000;
  pointer-events: none;
  width:100%;

}

.lead-checkbox.custom-control-label::before {
  border: #0397cc solid 2px;
}
.lead-email-btn {
  border-bottom: 1px solid #e0dee2;
}
.lead-email-btn.dropdown-toggle::after {
  float: right;
  margin-top: 12px;
}
.condition-text {
  color: #0397cc;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
.date-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.active-svg {
  padding: 3px;
  background: #b3afb6;
  border-radius: 3px;
}

.big-div {
  padding: 0px;
}

@media only screen and (max-width: 767px) {
  .big-div {
    width: 100% !important;
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 1366px) {
  .big-div {
    padding: 0px 15px !important;
  }
}

.checkboxDiv input[type="checkbox"] {
  accent-color: var(--selectedCheckboxColor);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #323232 !important;
  border: none;
}

.custom-checkbox
  .custom-control-input:checked:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  border: none;
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  border: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.text-setting select {
  font-size: 14px;
  padding: 0;
}

.text-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--inputTextColor);
}
.text-input::placeholder {
  color: var(--inputPlaceholderColor);
}

.date-input::placeholder {
  color: var(--inputPlaceholderColor);
}

.CustomTextInput {
  color: var(--inputColor) !important;
}

/* DatePicker styles */
.CustomDatePicker >>> button svg path {
  fill: var(--inputColor) !important;
}
.CustomDatePicker >>> label.form-control {
  color: var(--inputColor) !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  /* position: absolute;
  left: 0;
   color:var(--inputColor) !important; */
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.defaultCalendar {
  width: 100%;
  padding: 0 5px;
  border: none;
}
input[type="date"]:focus {
  border-color: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  outline: 0 none !important;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 0px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 0px;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  /* position: absolute !important;
   left: 30px;  */
  color: var(--inputColor) !important;
}

input[type="date"]::-webkit-datetime-edit-month-field {
  /* position: absolute !important;
  padding: 0 20px;
  left: 26px; */
  color: var(--inputColor) !important;
}

input[type="date"]::-webkit-datetime-edit-day-field {
  /* position: absolute !important;
  padding: 2px;
  left: 4px; */
  color: var(--inputColor) !important;
}

/* input[type="date"]:before {
    content: attr(placeholder) !important;
     color:var(--inputColor) !important;
  } */

.telephoneInput >>> input {
  background: transparent;
}

.telephoneInput >>> ul.vti__dropdown-list {
  position: unset;
}
</style>